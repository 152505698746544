.notfound {
    min-height: 80vh;
    background-color: #24282b;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 1.2em;
}

.block {
    color: white;
    padding: 30px 10px;
}

.notfoundheader {
    margin: 0;
}

.returntomain,
.returntomain:visited {
    color: white;
    font-weight: bold;
    text-decoration: none;
}

.returntomain:hover {
    transition: 0.2s color;
    color: orange;
}