body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.footer-wrapper {
	background-color: black;
	color: white;
    width: 100%;
}

.footer {
    padding: 10px 0;
    width: 80%;
    margin: 0 auto;
    text-align: left;
    font-size: 0.8em;
    max-width: 1000px;
    font-family: 'Quicksand', sans-serif;
}


.media-logo {
    display: inline-block;
    width: 70px;
    height: 70px;
    position: relative;
    bottom: 20%;
    right: 2px;
    padding-right: 20px;
}
.menu-wrapper {
    min-height: 100vh;
    font-family: 'Josefin Sans', sans-serif;
    border-top: 1px solid black;
    background-color: #24282b;
}

.menu-item {
    padding: 20px;
    min-width: 240px;
    background-color: rgb(255, 255, 255);
    width: 100%;
    padding: 10px 0;
    border: 0;
    display: inline-block;
    vertical-align: top;
}

.menu-item-num {
    width: 14%;
    font-size: 1.2em;
    padding: 5px;
    border-top: 1px solid black;
    border-left: 1px solid black;
    border-bottom-right-radius: 10px;
    position: absolute;
    top: -1px;
    left: -1px;
    background-color: rgb(235, 235, 235);
}

.menu-item-wrapper {
    width: 100%;
    text-align: center;
}

.menu-item-name {
    padding: 0 20px;
    font-size: 1.7em;
    margin: 0;
}

.menu-item-price {
    font-size: 1.3em;
    font-weight: 700;
    color: rgb(34, 30, 29);
}

.menu-item-price-caption {
    position: absolute;
    font-size: 0.9em;
    width: 100%;
    top: -23px;
    text-align: center;
    color: rgb(136, 25, 25);
}

.menu-item-price-sep {
    color: rgb(0, 0, 0);
    padding: 0 5px;
}

.menu-item-price-div {
    display: inline-block;
    position: relative;
}

.menu-section-header {
    font-size: 3em;
    padding: 10px 0;
    background-color: rgb(107, 58, 29);
    color: white;
}

.menu-item-img {
    width: 100%;
    padding: 10px 0;
}

.menu-item-group {
    background-color: rgb(107, 58, 29);
}

.quick-select-section {
    display: flex;
    background-color: rgb(41, 41, 41);
    border-bottom: 1px solid black;
    height: 100%;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.quick-select-item {
    display: table;
    position: relative;
    flex-shrink: 0;
    height: 200px;
    cursor: pointer;
}

.quick-select-header-wrapper {
    display: table-cell;
    font-size: 2em;
    color: rgb(0, 0, 0);
    margin: 0;
    padding: 0px 80px;
    border-right: 1px solid black;
    width: 100%;
    text-align: center;
    vertical-align: middle;
    height: 100%;
    position: relative;
}

.quick-select-featured-header {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    color: white;
}

.quick-select-featured-header-wrapper {
    width: 100%;
    text-align: center;
    margin-top: 50%;
}

.quick-select-img {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0.6;
}

.notice {
    color: white;
    font-size: 0.9em;
}

.notice > p {
    margin: 0.5em;
}

.back-to-top {
    position: fixed;
    bottom: 10px;
    right: 10px;
    font-size: 1.2em;
    padding: 10px 15px;
    cursor: pointer;
}

/* mobile */
@media screen and (max-width: 550px) {
    .menu-section-header {
        font-size: 2em;

    }
    .menu-item {
        position: relative;
        width: 100%;
        padding: 10px 0;
        border: 0;
        border-top: 2px solid black;
        text-align: left;
    }

    .menu-item-img {
        padding-left: 30px;
        display: inline-block;
        width: 25%;
        vertical-align: middle;
    }

    .menu-item-wrapper {
        display: inline-block;
        width: 60%;
        margin: 0;
        vertical-align: middle;
    }

    .menu-item-name {
        font-size: 4.5vw;
        margin: 0;
        margin-bottom: 5%;
    }

    .menu-item-price {
        /*font-size: 1.25em;*/
        font-size: 4.5vw;
        color: rgb(58, 54, 53);
        padding-top: 10px;
    }
    
    .menu-item-price-caption {
        position: absolute;
        font-size: 0.85em;
        width: 100%;
        top: -18px;
        text-align: center;
        color: rgb(158, 31, 31);
    }

    .menu-item-num {
        width: 6%;
        font-size: 1.1em;
        padding: 5px;
        border-bottom-right-radius: 10px;
        position: absolute;
        top: -1px;
        left: -1px;
        text-align: center;
        background-color: rgb(235, 235, 235);
    }

    .menu-section {
        border-bottom: 2px solid black;
    }
}

/* 2 */
@media screen and (min-width: 551px) {
    .menu-item {
        position: relative;
        display: flex;
        flex-flow: column;
        padding-bottom: 60px;
    }

    .menu-section-header {
        font-size: 2.5em;
    }

    .menu-item-group {
        display: grid;
        grid-template-columns: repeat(2, 50%);
        grid-gap: 1px;
    }

    .menu-item-img {
        width: 60%;
        margin: 0 auto;
        vertical-align: top;
        flex: 1 1;
    }
    
    .menu-item-name {
        font-size: 1.5em;
    }

    .menu-item-price-wrapper {
        position: absolute;
        bottom: 10px;
        width: 100%;
    }

    .menu-item-price {
        margin: 0 auto;
        font-size: 1.2em;
    }

    .menu-section {
        border-bottom: 1px solid black;
    }
}

/* 3 */
@media screen and (min-width: 750px) {
    .menu-item {
        position: relative;
    }

    .menu-section-header {
        font-size: 2.7em;
    }

    .menu-item-group {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 1px;
    }

    .menu-item-name {
        font-size: 1.5em;
        vertical-align: middle;
    }

    .menu-item-img {
        width: 60%;
        margin: 0 auto;
        vertical-align: top;
        flex: 1 1;
    }

    .menu-item {
        display: flex;
        flex-flow: column;
        padding-bottom: 60px;
    }

    .menu-item-price-wrapper {
        position: absolute;
        bottom: 10px;
        width: 100%;
    }

    .menu-item-price {
        margin: 0 auto;
        font-size: 1.2em;
    }

    .menu-item {
        position: relative;
    }

    .menu-section {
        border-bottom: 2px solid black;
    }
}

@media screen and (min-width: 1000px) {
    .menu-item {
        position: relative;
    }

    .menu-item-group {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1px;
    }

    .menu-section-header {
        font-size: 2.9em;
        padding: 10px 0;
    }

    .menu-item-name {
        font-size: 1.8em;
    }

    .menu-item-img {
        width: 60%;
    }

    .menu-section {
        border-bottom: 2px solid black;
    }
}

@media screen and (min-width: 1400px) {
    .menu-item {
        position: relative;
    }

    .menu-item-group {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 1px;
    }

    .menu-section-header {
        font-size: 3.3em;
        padding: 10px 0;
    }

    .menu-section {
        border-bottom: 2px solid black;
    }
}
.aboutus {
    min-height: 80vh;
    background-color: #24282b;
    color: white;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 1.3em;
}

.aboutusimg {
    margin: 0;
    width: 100%;
	height: auto;
    max-height: 380px;
}

.aboutus-text-wrapper {
    max-width: 1000px;
    margin: 0 auto;
    text-align: left;
}

.aboutustext {
    color: white;
    margin: 0;
    padding: 30px 30px;
    line-height: 35px;
}
.contact {
    min-height: 80vh;
    background-color: #24282b;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 1.2em;
}

.block {
    color: white;
    padding: 30px 10px;
}

.contactheader {
    margin: 0;
}

.highlight-closed {
    color: rgb(199, 81, 81);
}

.highlight-open {
    color: rgb(81, 199, 81);
}

.highlight-temporary {
    color: rgb(207, 207, 207);
}
.notfound {
    min-height: 80vh;
    background-color: #24282b;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 1.2em;
}

.block {
    color: white;
    padding: 30px 10px;
}

.notfoundheader {
    margin: 0;
}

.returntomain,
.returntomain:visited {
    color: white;
    font-weight: bold;
    text-decoration: none;
}

.returntomain:hover {
    transition: 0.2s color;
    color: orange;
}
.header-wrapper {
    padding: 35px 0;
    background: #f7d643;
}

.logo {
    background-image: url(/static/media/dhmainlogo.86a84400.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 10vh;
}
.nav-bar-container-wrapper {
    margin: 0 auto;
    padding: 0;
    background-color: #110805;
    font-family: 'Quicksand', cursive;
    font-weight: 300;
    color: white;
}

.nav-bar-container {
    height: 45px;
    width: 100%;
    color: white;
    font-size: 1.1em;
    display: table;
    margin: 0 auto;
    max-width: 1000px;
}

.nav-bar-elem {
    width: 19%;
    line-height: 50px;
    display: table-cell;
    color: white;
}

.nav-bar-elem-img {
    display: inline-block;
    padding-right: 15px;
}

.nav-bar-elem-item {
    display: inline-block;
}


.nav-bar-elem:hover,
.nav-bar-control:hover {
    cursor: pointer;
}

a {
    color: black;
    text-decoration: none;
}

@media screen and (max-width: 550px) {
    #nav-bar-container {
        display: block;
        margin: 0 auto;
        height: 40px;
    }

    .nav-bar-elem {
        display: block;
        width: 100%;
        line-height: 40px;
    }

    .nav-bar-control {
        display: block;
    }
}


.nav-bar-elem-a {
    text-decoration: none;
    padding: 0;
    margin: 0;
    color: white;
}

.nav-bar-elem-highlight:hover {
    color: white;
    font-weight: bold;
    transition: 0.35s font-weight;
    cursor: pointer;
}

.nav-bar-elem-container {
    display: inline-block;
}
.App {
  text-align: center;
  display: block;
  margin: 0;
  padding: 0;
  min-width: 315px;
}

.title {
  margin: 0;
  font-size: 2.5em;
  font-family: 'Advent Pro', sans-serif;
  min-width: 300px;
}

.subtitle {
  margin: 0;
  font-size: 0.85em;
  font-family: 'Advent Pro', sans-serif;
  min-width: 300px;
}

