.header-wrapper {
    padding: 35px 0;
    background: #f7d643;
}

.logo {
    background-image: url('./dhmainlogo.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 10vh;
}