.menu-wrapper {
    min-height: 100vh;
    font-family: 'Josefin Sans', sans-serif;
    border-top: 1px solid black;
    background-color: #24282b;
}

.menu-item {
    padding: 20px;
    min-width: 240px;
    background-color: rgb(255, 255, 255);
    width: 100%;
    padding: 10px 0;
    border: 0;
    display: inline-block;
    vertical-align: top;
}

.menu-item-num {
    width: 14%;
    font-size: 1.2em;
    padding: 5px;
    border-top: 1px solid black;
    border-left: 1px solid black;
    border-bottom-right-radius: 10px;
    position: absolute;
    top: -1px;
    left: -1px;
    background-color: rgb(235, 235, 235);
}

.menu-item-wrapper {
    width: 100%;
    text-align: center;
}

.menu-item-name {
    padding: 0 20px;
    font-size: 1.7em;
    margin: 0;
}

.menu-item-price {
    font-size: 1.3em;
    font-weight: 700;
    color: rgb(34, 30, 29);
}

.menu-item-price-caption {
    position: absolute;
    font-size: 0.9em;
    width: 100%;
    top: -23px;
    text-align: center;
    color: rgb(136, 25, 25);
}

.menu-item-price-sep {
    color: rgb(0, 0, 0);
    padding: 0 5px;
}

.menu-item-price-div {
    display: inline-block;
    position: relative;
}

.menu-section-header {
    font-size: 3em;
    padding: 10px 0;
    background-color: rgb(107, 58, 29);
    color: white;
}

.menu-item-img {
    width: 100%;
    padding: 10px 0;
}

.menu-item-group {
    background-color: rgb(107, 58, 29);
}

.quick-select-section {
    display: flex;
    background-color: rgb(41, 41, 41);
    border-bottom: 1px solid black;
    height: 100%;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.quick-select-item {
    display: table;
    position: relative;
    flex-shrink: 0;
    height: 200px;
    cursor: pointer;
}

.quick-select-header-wrapper {
    display: table-cell;
    font-size: 2em;
    color: rgb(0, 0, 0);
    margin: 0;
    padding: 0px 80px;
    border-right: 1px solid black;
    width: 100%;
    text-align: center;
    vertical-align: middle;
    height: 100%;
    position: relative;
}

.quick-select-featured-header {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    color: white;
}

.quick-select-featured-header-wrapper {
    width: 100%;
    text-align: center;
    margin-top: 50%;
}

.quick-select-img {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0.6;
}

.notice {
    color: white;
    font-size: 0.9em;
}

.notice > p {
    margin: 0.5em;
}

.back-to-top {
    position: fixed;
    bottom: 10px;
    right: 10px;
    font-size: 1.2em;
    padding: 10px 15px;
    cursor: pointer;
}

/* mobile */
@media screen and (max-width: 550px) {
    .menu-section-header {
        font-size: 2em;

    }
    .menu-item {
        position: relative;
        width: 100%;
        padding: 10px 0;
        border: 0;
        border-top: 2px solid black;
        text-align: left;
    }

    .menu-item-img {
        padding-left: 30px;
        display: inline-block;
        width: 25%;
        vertical-align: middle;
    }

    .menu-item-wrapper {
        display: inline-block;
        width: 60%;
        margin: 0;
        vertical-align: middle;
    }

    .menu-item-name {
        font-size: 4.5vw;
        margin: 0;
        margin-bottom: 5%;
    }

    .menu-item-price {
        /*font-size: 1.25em;*/
        font-size: 4.5vw;
        color: rgb(58, 54, 53);
        padding-top: 10px;
    }
    
    .menu-item-price-caption {
        position: absolute;
        font-size: 0.85em;
        width: 100%;
        top: -18px;
        text-align: center;
        color: rgb(158, 31, 31);
    }

    .menu-item-num {
        width: 6%;
        font-size: 1.1em;
        padding: 5px;
        border-bottom-right-radius: 10px;
        position: absolute;
        top: -1px;
        left: -1px;
        text-align: center;
        background-color: rgb(235, 235, 235);
    }

    .menu-section {
        border-bottom: 2px solid black;
    }
}

/* 2 */
@media screen and (min-width: 551px) {
    .menu-item {
        position: relative;
        display: flex;
        flex-flow: column;
        padding-bottom: 60px;
    }

    .menu-section-header {
        font-size: 2.5em;
    }

    .menu-item-group {
        display: grid;
        grid-template-columns: repeat(2, 50%);
        grid-gap: 1px;
    }

    .menu-item-img {
        width: 60%;
        margin: 0 auto;
        vertical-align: top;
        flex: 1;
    }
    
    .menu-item-name {
        font-size: 1.5em;
    }

    .menu-item-price-wrapper {
        position: absolute;
        bottom: 10px;
        width: 100%;
    }

    .menu-item-price {
        margin: 0 auto;
        font-size: 1.2em;
    }

    .menu-section {
        border-bottom: 1px solid black;
    }
}

/* 3 */
@media screen and (min-width: 750px) {
    .menu-item {
        position: relative;
    }

    .menu-section-header {
        font-size: 2.7em;
    }

    .menu-item-group {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 1px;
    }

    .menu-item-name {
        font-size: 1.5em;
        vertical-align: middle;
    }

    .menu-item-img {
        width: 60%;
        margin: 0 auto;
        vertical-align: top;
        flex: 1;
    }

    .menu-item {
        display: flex;
        flex-flow: column;
        padding-bottom: 60px;
    }

    .menu-item-price-wrapper {
        position: absolute;
        bottom: 10px;
        width: 100%;
    }

    .menu-item-price {
        margin: 0 auto;
        font-size: 1.2em;
    }

    .menu-item {
        position: relative;
    }

    .menu-section {
        border-bottom: 2px solid black;
    }
}

@media screen and (min-width: 1000px) {
    .menu-item {
        position: relative;
    }

    .menu-item-group {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1px;
    }

    .menu-section-header {
        font-size: 2.9em;
        padding: 10px 0;
    }

    .menu-item-name {
        font-size: 1.8em;
    }

    .menu-item-img {
        width: 60%;
    }

    .menu-section {
        border-bottom: 2px solid black;
    }
}

@media screen and (min-width: 1400px) {
    .menu-item {
        position: relative;
    }

    .menu-item-group {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 1px;
    }

    .menu-section-header {
        font-size: 3.3em;
        padding: 10px 0;
    }

    .menu-section {
        border-bottom: 2px solid black;
    }
}