.aboutus {
    min-height: 80vh;
    background-color: #24282b;
    color: white;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 1.3em;
}

.aboutusimg {
    margin: 0;
    width: 100%;
	height: auto;
    max-height: 380px;
}

.aboutus-text-wrapper {
    max-width: 1000px;
    margin: 0 auto;
    text-align: left;
}

.aboutustext {
    color: white;
    margin: 0;
    padding: 30px 30px;
    line-height: 35px;
}