.contact {
    min-height: 80vh;
    background-color: #24282b;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 1.2em;
}

.block {
    color: white;
    padding: 30px 10px;
}

.contactheader {
    margin: 0;
}

.highlight-closed {
    color: rgb(199, 81, 81);
}

.highlight-open {
    color: rgb(81, 199, 81);
}

.highlight-temporary {
    color: rgb(207, 207, 207);
}