.footer-wrapper {
	background-color: black;
	color: white;
    width: 100%;
}

.footer {
    padding: 10px 0;
    width: 80%;
    margin: 0 auto;
    text-align: left;
    font-size: 0.8em;
    max-width: 1000px;
    font-family: 'Quicksand', sans-serif;
}


.media-logo {
    display: inline-block;
    width: 70px;
    height: 70px;
    position: relative;
    bottom: 20%;
    right: 2px;
    padding-right: 20px;
}