.nav-bar-container-wrapper {
    margin: 0 auto;
    padding: 0;
    background-color: #110805;
    font-family: 'Quicksand', cursive;
    font-weight: 300;
    color: white;
}

.nav-bar-container {
    height: 45px;
    width: 100%;
    color: white;
    font-size: 1.1em;
    display: table;
    margin: 0 auto;
    max-width: 1000px;
}

.nav-bar-elem {
    width: 19%;
    line-height: 50px;
    display: table-cell;
    color: white;
}

.nav-bar-elem-img {
    display: inline-block;
    padding-right: 15px;
}

.nav-bar-elem-item {
    display: inline-block;
}


.nav-bar-elem:hover,
.nav-bar-control:hover {
    cursor: pointer;
}

a {
    color: black;
    text-decoration: none;
}

@media screen and (max-width: 550px) {
    #nav-bar-container {
        display: block;
        margin: 0 auto;
        height: 40px;
    }

    .nav-bar-elem {
        display: block;
        width: 100%;
        line-height: 40px;
    }

    .nav-bar-control {
        display: block;
    }
}


.nav-bar-elem-a {
    text-decoration: none;
    padding: 0;
    margin: 0;
    color: white;
}

.nav-bar-elem-highlight:hover {
    color: white;
    font-weight: bold;
    transition: 0.35s font-weight;
    cursor: pointer;
}

.nav-bar-elem-container {
    display: inline-block;
}