.App {
  text-align: center;
  display: block;
  margin: 0;
  padding: 0;
  min-width: 315px;
}

.title {
  margin: 0;
  font-size: 2.5em;
  font-family: 'Advent Pro', sans-serif;
  min-width: 300px;
}

.subtitle {
  margin: 0;
  font-size: 0.85em;
  font-family: 'Advent Pro', sans-serif;
  min-width: 300px;
}
